import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type OrderItemItemFragment = { __typename: 'OrderItemObject', id: number, adjustmentsTotal: number, createdAt: any, productName: string, quantity: number, taxAmount: number, taxRate: number, total: number, unitPrice: number, unitsTotal: number, updatedAt: any, variantId: number, variantName?: string | null | undefined, source: Types.OrderItemSource, variant: { __typename: 'ProductVariantObject', id: number, stockItem?: { __typename: 'StockItemObject', id: number, sku: string } | null | undefined } };

export type OrderItemRowFragment = { __typename: 'OrderItemObject', id: number, adjustmentsTotal: number, createdAt: any, productName: string, quantity: number, taxAmount: number, taxRate: number, total: number, unitPrice: number, unitsTotal: number, updatedAt: any, variantId: number, variantName?: string | null | undefined, source: Types.OrderItemSource, variant: { __typename: 'ProductVariantObject', id: number, stockItem?: { __typename: 'StockItemObject', id: number, sku: string } | null | undefined }, order: { __typename: 'OrderObject', id: number, number?: string | null | undefined, deliveryShipment?: { __typename: 'ShipmentObject', id: number, state: Types.ShipmentState, slot?: { __typename: 'ShippingSlotObject', id: string, startAt: any, endAt: any } | null | undefined } | null | undefined } };

export type OrderItemRowsQueryVariables = Types.Exact<{
  sort?: Types.InputMaybe<Types.SortInput>;
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.OrderItemFilterInput>;
}>;


export type OrderItemRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'OrderItemObject', id: number, adjustmentsTotal: number, createdAt: any, productName: string, quantity: number, taxAmount: number, taxRate: number, total: number, unitPrice: number, unitsTotal: number, updatedAt: any, variantId: number, variantName?: string | null | undefined, source: Types.OrderItemSource, variant: { __typename: 'ProductVariantObject', id: number, stockItem?: { __typename: 'StockItemObject', id: number, sku: string } | null | undefined }, order: { __typename: 'OrderObject', id: number, number?: string | null | undefined, deliveryShipment?: { __typename: 'ShipmentObject', id: number, state: Types.ShipmentState, slot?: { __typename: 'ShippingSlotObject', id: string, startAt: any, endAt: any } | null | undefined } | null | undefined } }> };

export type OrderItemRowQueryVariables = Types.Exact<{
  orderItemId: Types.Scalars['Int']['input'];
}>;


export type OrderItemRowQuery = { __typename: 'Query', orderItem: { __typename: 'OrderItemObject', id: number, adjustmentsTotal: number, createdAt: any, productName: string, quantity: number, taxAmount: number, taxRate: number, total: number, unitPrice: number, unitsTotal: number, updatedAt: any, variantId: number, variantName?: string | null | undefined, source: Types.OrderItemSource, variant: { __typename: 'ProductVariantObject', id: number, stockItem?: { __typename: 'StockItemObject', id: number, sku: string } | null | undefined }, order: { __typename: 'OrderObject', id: number, number?: string | null | undefined, deliveryShipment?: { __typename: 'ShipmentObject', id: number, state: Types.ShipmentState, slot?: { __typename: 'ShippingSlotObject', id: string, startAt: any, endAt: any } | null | undefined } | null | undefined } } };

export const OrderItemItemFragmentDoc = gql`
    fragment OrderItemItem on OrderItemObject {
  id
  adjustmentsTotal
  createdAt
  productName
  quantity
  taxAmount
  taxRate
  total
  unitPrice
  unitsTotal
  updatedAt
  variantId
  variantName
  source
  variant {
    id
    stockItem {
      id
      sku
    }
  }
}
    `;
export const OrderItemRowFragmentDoc = gql`
    fragment OrderItemRow on OrderItemObject {
  id
  adjustmentsTotal
  createdAt
  productName
  quantity
  taxAmount
  taxRate
  total
  unitPrice
  unitsTotal
  updatedAt
  variantId
  variantName
  source
  variant {
    id
    stockItem {
      id
      sku
    }
  }
  order {
    id
    number
    deliveryShipment {
      id
      state
      slot {
        id
        startAt
        endAt
      }
    }
  }
}
    `;
export const OrderItemRowsDocument = gql`
    query OrderItemRows($sort: SortInput, $page: PageInput, $filter: OrderItemFilterInput) {
  items: orderItems(sort: $sort, page: $page, filter: $filter) {
    ...OrderItemRow
  }
  totalItemsCount: orderItemsCount(filter: $filter)
}
    ${OrderItemRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderItemRowsGQL extends Apollo.Query<OrderItemRowsQuery, OrderItemRowsQueryVariables> {
    document = OrderItemRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const OrderItemRowDocument = gql`
    query OrderItemRow($orderItemId: Int!) {
  orderItem(id: $orderItemId) {
    ...OrderItemRow
  }
}
    ${OrderItemRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class OrderItemRowGQL extends Apollo.Query<OrderItemRowQuery, OrderItemRowQueryVariables> {
    document = OrderItemRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }