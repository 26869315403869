import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ShippingScheduleItemFragmentDoc } from './shipping-schedule.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShippingMethodItemFragment = { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, timezone: string, color?: string | null | undefined, enabled: boolean };

export type ShippingMethodRowFragment = { __typename: 'ShippingMethodObject', id: number, code: string, name: string, timezone: string, provider: string, costCalculator?: string | null | undefined, labelGenerator?: string | null | undefined, priority: number, slotRequired: boolean, collectionRequired: boolean, color?: string | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, quoteValidityDuration?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, warehouse: { __typename: 'WarehouseObject', id: number, name: string }, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, categories: Array<{ __typename: 'ShippingCategoryObject', id: number, name: string, code: string }>, schedules: Array<{ __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined }> };

export type ShippingMethodFragment = { __typename: 'ShippingMethodObject', id: number, name: string, code: string, provider: string, timezone: string, costCalculator?: string | null | undefined, labelGenerator?: string | null | undefined, slotRequired: boolean, collectionRequired: boolean, color?: string | null | undefined, enabled: boolean, channels: Array<{ __typename: 'ChannelObject', code: string, enabled: boolean }> };

export type ShippingMethodDetailFragment = { __typename: 'ShippingMethodObject', id: number, code: string, name: string, displayName?: string | null | undefined, description?: string | null | undefined, timezone: string, priority: number, provider: string, providerConfig?: any | null | undefined, costCalculator?: string | null | undefined, costCalculatorConfig?: any | null | undefined, labelGenerator?: string | null | undefined, labelGeneratorConfig?: any | null | undefined, stockType: Types.StockType, slotRequired: boolean, groupDestination: boolean, collectionRequired: boolean, quoteValidityDuration?: any | null | undefined, categoryComparison: Types.ShippingCategoryComparison, color?: string | null | undefined, enabled: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, collectionPoints: Array<{ __typename: 'CollectionPointObject', id: number, name: string }>, warehouse: { __typename: 'WarehouseObject', id: number, name: string }, schedules: Array<{ __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined }>, costProductVariant?: { __typename: 'ProductVariantObject', id: number, name: string, product: { __typename: 'ProductObject', id: number, name: string } } | null | undefined, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, categories: Array<{ __typename: 'ShippingCategoryObject', id: number, name: string, code: string }> };

export type CreateShippingMethodMutationVariables = Types.Exact<{
  input: Types.CreateShippingMethodInput;
}>;


export type CreateShippingMethodMutation = { __typename: 'Mutation', createShippingMethod: { __typename: 'ShippingMethodObject', id: number, code: string, name: string } };

export type UpdateShippingMethodMutationVariables = Types.Exact<{
  input: Types.UpdateShippingMethodInput;
}>;


export type UpdateShippingMethodMutation = { __typename: 'Mutation', updateShippingMethod: { __typename: 'ShippingMethodObject', id: number, code: string, name: string } };

export type UpdateChannelEnabledMutationVariables = Types.Exact<{
  input: Types.ShippingMethodChannelEnabledInput;
}>;


export type UpdateChannelEnabledMutation = { __typename: 'Mutation', updateShippingMethodChannelEnabled: { __typename: 'ShippingMethodObject', id: number, code: string, name: string } };

export type ShippingMethodSearchQueryVariables = Types.Exact<{
  searchText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  results: Types.Scalars['Int']['input'];
}>;


export type ShippingMethodSearchQuery = { __typename: 'Query', shippingMethods: Array<{ __typename: 'ShippingMethodObject', id: number, code: string, name: string }> };

export type ShippingMethodByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShippingMethodByIdQuery = { __typename: 'Query', shippingMethod: { __typename: 'ShippingMethodObject', id: number, code: string, name: string } };

export type ShippingMethodItemQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShippingMethodItemQuery = { __typename: 'Query', shippingMethod: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, timezone: string, color?: string | null | undefined, enabled: boolean } };

export type ShippingMethodItemsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ShippingMethodFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingMethodItemsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, timezone: string, color?: string | null | undefined, enabled: boolean }> };

export type ShippingMethodDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShippingMethodDetailQuery = { __typename: 'Query', shippingMethod: { __typename: 'ShippingMethodObject', id: number, code: string, name: string, displayName?: string | null | undefined, description?: string | null | undefined, timezone: string, priority: number, provider: string, providerConfig?: any | null | undefined, costCalculator?: string | null | undefined, costCalculatorConfig?: any | null | undefined, labelGenerator?: string | null | undefined, labelGeneratorConfig?: any | null | undefined, stockType: Types.StockType, slotRequired: boolean, groupDestination: boolean, collectionRequired: boolean, quoteValidityDuration?: any | null | undefined, categoryComparison: Types.ShippingCategoryComparison, color?: string | null | undefined, enabled: boolean, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, collectionPoints: Array<{ __typename: 'CollectionPointObject', id: number, name: string }>, warehouse: { __typename: 'WarehouseObject', id: number, name: string }, schedules: Array<{ __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined }>, costProductVariant?: { __typename: 'ProductVariantObject', id: number, name: string, product: { __typename: 'ProductObject', id: number, name: string } } | null | undefined, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, categories: Array<{ __typename: 'ShippingCategoryObject', id: number, name: string, code: string }> } };

export type ShippingMethodRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ShippingMethodFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingMethodRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ShippingMethodObject', id: number, code: string, name: string, timezone: string, provider: string, costCalculator?: string | null | undefined, labelGenerator?: string | null | undefined, priority: number, slotRequired: boolean, collectionRequired: boolean, color?: string | null | undefined, enabled: boolean, createdAt: any, updatedAt: any, quoteValidityDuration?: any | null | undefined, channels: Array<{ __typename: 'ChannelObject', id: number, code: string, name: string }>, warehouse: { __typename: 'WarehouseObject', id: number, name: string }, regions: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }>, categories: Array<{ __typename: 'ShippingCategoryObject', id: number, name: string, code: string }>, schedules: Array<{ __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined }> }> };

export type ShippingProvidersQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShippingProvidersQuery = { __typename: 'Query', shippingProviders: Array<{ __typename: 'ShippingProviderObject', id: string, label: string }> };

export type ShipmentLabelGeneratorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShipmentLabelGeneratorsQuery = { __typename: 'Query', shipmentLabelGenerators: Array<{ __typename: 'ShipmentLabelGeneratorObject', id: string, label: string }> };

export type ShippingCostCalculatorsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShippingCostCalculatorsQuery = { __typename: 'Query', shippingCostCalculators: Array<{ __typename: 'ShippingCostCalculatorObject', id: string, label: string }> };

export const ShippingMethodItemFragmentDoc = gql`
    fragment ShippingMethodItem on ShippingMethodObject {
  id
  name
  code
  slotRequired
  collectionRequired
  provider
  timezone
  color
  enabled
}
    `;
export const ShippingMethodRowFragmentDoc = gql`
    fragment ShippingMethodRow on ShippingMethodObject {
  id
  code
  name
  timezone
  provider
  costCalculator
  labelGenerator
  priority
  slotRequired
  collectionRequired
  color
  enabled
  channels {
    id
    code
    name
  }
  createdAt
  updatedAt
  quoteValidityDuration
  warehouse {
    id
    name
  }
  regions {
    id
    name
    code
  }
  categories {
    id
    name
    code
  }
  schedules {
    ...ShippingScheduleItem
  }
}
    ${ShippingScheduleItemFragmentDoc}`;
export const ShippingMethodFragmentDoc = gql`
    fragment shippingMethod on ShippingMethodObject {
  id
  name
  code
  provider
  timezone
  costCalculator
  labelGenerator
  slotRequired
  collectionRequired
  color
  enabled
  channels {
    code
    enabled
  }
}
    `;
export const ShippingMethodDetailFragmentDoc = gql`
    fragment ShippingMethodDetail on ShippingMethodObject {
  id
  code
  name
  displayName
  description
  timezone
  priority
  provider
  providerConfig
  costCalculator
  costCalculatorConfig
  labelGenerator
  labelGeneratorConfig
  stockType
  slotRequired
  groupDestination
  collectionRequired
  quoteValidityDuration
  categoryComparison
  slotRequired
  collectionRequired
  color
  enabled
  channels {
    id
    code
    name
  }
  collectionPoints {
    id
    name
  }
  warehouse {
    id
    name
  }
  schedules {
    ...ShippingScheduleItem
  }
  costProductVariant {
    id
    name
    product {
      id
      name
    }
  }
  regions {
    id
    name
    code
  }
  categories {
    id
    name
    code
  }
}
    ${ShippingScheduleItemFragmentDoc}`;
export const CreateShippingMethodDocument = gql`
    mutation CreateShippingMethod($input: CreateShippingMethodInput!) {
  createShippingMethod(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShippingMethodGQL extends Apollo.Mutation<CreateShippingMethodMutation, CreateShippingMethodMutationVariables> {
    document = CreateShippingMethodDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShippingMethodDocument = gql`
    mutation UpdateShippingMethod($input: UpdateShippingMethodInput!) {
  updateShippingMethod(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShippingMethodGQL extends Apollo.Mutation<UpdateShippingMethodMutation, UpdateShippingMethodMutationVariables> {
    document = UpdateShippingMethodDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateChannelEnabledDocument = gql`
    mutation UpdateChannelEnabled($input: ShippingMethodChannelEnabledInput!) {
  updateShippingMethodChannelEnabled(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateChannelEnabledGQL extends Apollo.Mutation<UpdateChannelEnabledMutation, UpdateChannelEnabledMutationVariables> {
    document = UpdateChannelEnabledDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodSearchDocument = gql`
    query ShippingMethodSearch($searchText: String, $results: Int!) {
  shippingMethods(filter: {search: $searchText}, page: {size: $results, index: 0}) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodSearchGQL extends Apollo.Query<ShippingMethodSearchQuery, ShippingMethodSearchQueryVariables> {
    document = ShippingMethodSearchDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodByIdDocument = gql`
    query ShippingMethodById($id: Int!) {
  shippingMethod(id: $id) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodByIdGQL extends Apollo.Query<ShippingMethodByIdQuery, ShippingMethodByIdQueryVariables> {
    document = ShippingMethodByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodItemDocument = gql`
    query ShippingMethodItem($id: Int!) {
  shippingMethod(id: $id) {
    ...ShippingMethodItem
  }
}
    ${ShippingMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodItemGQL extends Apollo.Query<ShippingMethodItemQuery, ShippingMethodItemQueryVariables> {
    document = ShippingMethodItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodItemsDocument = gql`
    query ShippingMethodItems($page: PageInput, $filter: ShippingMethodFilterInput, $sort: SortInput) {
  items: shippingMethods(page: $page, filter: $filter, sort: $sort) {
    ...ShippingMethodItem
  }
  totalItemsCount: shippingMethodsCount(filter: $filter)
}
    ${ShippingMethodItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodItemsGQL extends Apollo.Query<ShippingMethodItemsQuery, ShippingMethodItemsQueryVariables> {
    document = ShippingMethodItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodDetailDocument = gql`
    query ShippingMethodDetail($id: Int!) {
  shippingMethod(id: $id) {
    ...ShippingMethodDetail
  }
}
    ${ShippingMethodDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodDetailGQL extends Apollo.Query<ShippingMethodDetailQuery, ShippingMethodDetailQueryVariables> {
    document = ShippingMethodDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingMethodRowsDocument = gql`
    query ShippingMethodRows($page: PageInput, $filter: ShippingMethodFilterInput, $sort: SortInput) {
  items: shippingMethods(page: $page, filter: $filter, sort: $sort) {
    ...ShippingMethodRow
  }
  totalItemsCount: shippingMethodsCount(filter: $filter)
}
    ${ShippingMethodRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingMethodRowsGQL extends Apollo.Query<ShippingMethodRowsQuery, ShippingMethodRowsQueryVariables> {
    document = ShippingMethodRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingProvidersDocument = gql`
    query ShippingProviders {
  shippingProviders {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingProvidersGQL extends Apollo.Query<ShippingProvidersQuery, ShippingProvidersQueryVariables> {
    document = ShippingProvidersDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShipmentLabelGeneratorsDocument = gql`
    query ShipmentLabelGenerators {
  shipmentLabelGenerators {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentLabelGeneratorsGQL extends Apollo.Query<ShipmentLabelGeneratorsQuery, ShipmentLabelGeneratorsQueryVariables> {
    document = ShipmentLabelGeneratorsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingCostCalculatorsDocument = gql`
    query ShippingCostCalculators {
  shippingCostCalculators {
    id
    label
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingCostCalculatorsGQL extends Apollo.Query<ShippingCostCalculatorsQuery, ShippingCostCalculatorsQueryVariables> {
    document = ShippingCostCalculatorsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }