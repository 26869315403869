import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  StockScheduleCreateInput,
  StockScheduleUpdateEnabledInput,
  StockScheduleUpdateInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import {
  CreateStockScheduleGQL,
  DeleteStockScheduleGQL,
  StockScheduleItemGQL,
  UpdateStockScheduleEnabledGQL,
  UpdateStockScheduleGQL,
} from './gql/stock-schedule.gql.generated';
import { IStockScheduleItemObject } from './types/stock-schedule';

@Injectable()
export class StockScheduleService {
  constructor(
    private itemGQL: StockScheduleItemGQL,
    private createGQL: CreateStockScheduleGQL,
    private updateGQL: UpdateStockScheduleGQL,
    private updateEnabledGQL: UpdateStockScheduleEnabledGQL,
    private readonly deleteStockScheduleGQL: DeleteStockScheduleGQL,
  ) {}

  fetchItem(id: number): Observable<IStockScheduleItemObject> {
    return this.itemGQL.fetch({ id }).pipe(mapFetchResultData((d) => d.stockSchedule));
  }

  create(input: StockScheduleCreateInput): Observable<IStockScheduleItemObject> {
    return this.createGQL.mutate({ input }).pipe(mapFetchResultData((d) => d.createStockSchedule));
  }

  update(input: StockScheduleUpdateInput): Observable<IStockScheduleItemObject> {
    return this.updateGQL.mutate({ input }).pipe(mapFetchResultData((d) => d.updateStockSchedule));
  }

  delete(id: number): Observable<number> {
    return this.deleteStockScheduleGQL
      .mutate({ id })
      .pipe(mapFetchResultData((r) => r.deleteStockSchedule));
  }

  updateEnabled(input: StockScheduleUpdateEnabledInput) {
    return this.updateEnabledGQL
      .mutate({ input })
      .pipe(mapFetchResultData((d) => d.updateStockScheduleEnabled));
  }
}
